import HttpRequest from './HttpRequest'

class KnowledgeBaseProvider extends HttpRequest {
  findAll () {
    return this.get('/knowledge-base')
  }

  findOne (id) {
    return this.get(`/knowledge-base/${id}`)
  }

  findAllByCategory (id) {
    return this.get(`/knowledge-base/${id}/category`)
  }

  createGroup (payload) {
    return this.post('/knowledge-base', payload)
  }

  updateGroup (id, payload) {
    return this.patch(`/knowledge-base/${id}`, payload)
  }

  deleteGroup (id) {
    return this.delete(`/knowledge-base/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/knowledge-base?page=${page}&limit=${limit}`, query)
  }
}

export default KnowledgeBaseProvider
